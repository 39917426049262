<template>

<div class="rc-container rc-background-back" cmpt="AdminRegisterNew"> <!-- main -->

  
  <DetailsRow 
    :companyId="companyId"
    
    v-on:companySaved="companySaved"
    />
  <SpacerRow />

</div><!-- main -->

</template>

<script>

import { mapGetters } from 'vuex'

import Catalog    from "@/domain/session/Catalog.js";
import ConstUtils from '@/utils/ConstUtils.js';
import Company from "@/domain/model/company/Company.js";

import SpacerRow from '@/components/row/SpacerRow.vue'

import DetailsRow from "../details/DetailsRow.vue";


export default {
  name: "admin-company-new",
  components: {
    SpacerRow,
    DetailsRow,
  },
  props: {
  },
  data() {
    return {
      WC: new Catalog(),
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'domain',
                ]),
    companyId: function() {
      var company = new Company(this.domain);
      company
        .withStateActive()
        .withFullDiscount()
        .withBillingPeriodMonthly()
        .done();
      if (this.domain) {
        this.domain.session().with(company);
      }
      return company.id();
    },
  },
  watch: {
    
  },
  created: function() {
  },
  mounted: function () {
    if (this.domain) {
      this.domain.session().companyName = null;
    }

    if (!this.auth_connected) {
      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN })
    } else {
      this.start = true;
    }
  },
  methods: {
    companySaved: function(value) {
      if (value) {
        var company = this.domain.companys().findById(value['value']);
        this.domain.session().with(company);
        this.domain.session().companyName = company.name();
        var params = {
            id: company.id(), 
        };
        this.$router.push( {
              name: ConstUtils.ROUTES.COMPANY.ADMIN.DETAILS,
              params: params,
            });
      }
    },

  }
}
</script>